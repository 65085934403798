import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';

/**
 * @deprecated qpIsNotNil is deprecated, please use {@link qimaIsNotNil} instead
 * @description
 * Useful to avoid a type error with the isNil function returning a boolean
 * @template T
 * @param {QimaOptionalType<T>} value The value to check if not nil
 * @returns { value is T} Enforce the type of the value
 */
export function qpIsNotNil<T>(value: QimaOptionalType<T>): value is T {
  return !isNil(value);
}
